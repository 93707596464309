import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from 'firebase/firestore';
import { db } from "../Lib/firebase";
import Navbar from "../Web/Navbar";
import Footer from "../Web/Footer";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Link } from "react-router-dom";

import "./BlogDetail.css";
import { FaCopy, FaAngleDoubleLeft } from "react-icons/fa";
import Memoji from "../materials/memoji.webp";
import JoyStick from "../materials/joystick.png";
import Sticker from "../materials/sticker.png";

const BlogDetail = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [positions, setPositions] = useState([]);
  const { id } = useParams();

  // Blog verisini Firebase'den çekme
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const blogDoc = doc(db, "blogs", id);
        const blogSnapshot = await getDoc(blogDoc);
        
        if (blogSnapshot.exists()) {
          const blogData = blogSnapshot.data();
          setBlog({
            id: blogSnapshot.id,
            title: blogData.Header,
            img: blogData.Image,
            contentBlocks: blogData.contentBlocks
          });
        }
      } catch (error) {
        console.error("Blog detayı çekilemedi:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  useEffect(() => {
    const generatePositions = () => {
      const newPositions = [];
      for (let i = 0; i < 15; i++) {
        let validPosition = false;
        let position;

        while (!validPosition) {
          position = {
            top: Math.random() * 100,
            left: Math.random() * 100,
          };

          const collision = newPositions.some(
            (pos) =>
              Math.abs(pos.top - position.top) < 20 &&
              Math.abs(pos.left - position.left) < 20
          );

          if (!collision) {
            validPosition = true;
          }
        }
        newPositions.push(position);
      }
      setPositions(newPositions);
    };

    generatePositions();
  }, []);

  const joystickImages = positions.map((pos, index) => (
    <img
      key={index}
      src={JoyStick}
      alt="Joystick"
      className="Joystick"
      style={{
        top: `${pos.top}%`,
        left: `${pos.left}%`,
        marginLeft: "-200px",
        marginTop: "200px",
        position: "absolute",
        width: "64px",
        opacity: "0.05",
      }}
    />
  ));

  const StickerImages = positions.map((pos, index) => (
    <img
      key={index}
      src={Sticker}
      alt="Joystick"
      className="Joystick"
      style={{
        top: `${pos.top}%`,
        left: `${pos.left}%`,
        marginLeft: "-400px",
        marginTop: "200px",
        position: "absolute",
        width: "256px",
        opacity: "0.05",
      }}
    />
  ));

  // State for scroll progress
  const [scrollTop, setScrollTop] = useState(0);

  // Scroll event handler
  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    setScrollTop(scrolled);
  };

  // Register scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // Function to highlight certain keywords
  const highlightText = (text) => {
    const keywords = {
      JavaScript: "#d73a49",
      function: "#005cc5",
      "console.log": "#6f42c1",
      const: "#e36209",
      return: "#0366d6",
    };

    let highlightedText = text;
    Object.keys(keywords).forEach((keyword) => {
      const color = keywords[keyword];
      const regex = new RegExp(`\\b${keyword}\\b`, "g");
      highlightedText = highlightedText.replace(
        regex,
        `<span style="color: ${color}; font-weight: bold;">${keyword}</span>`
      );
    });

    return highlightedText;
  };

  // Helper function to format content with HTML line breaks
  const formatContent = (content) => {
    if (!content) return '';
    return content.replace(/\\n/g, '<br/>');
  };

  // Loading durumu kontrolü
  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  // Blog bulunamadı kontrolü
  if (!blog) {
    return <div>Blog bulunamadı</div>;
  }

  return (
    <div>
      {joystickImages}
      {StickerImages}
      <div className="progressMainWrapper">
        <div
          className="progressMainStyle"
          style={{ width: `${scrollTop}%` }}
        ></div>
      </div>
      <Navbar />
      <img src={Memoji} alt="memoji" className="Memoji" />
      <div className="BlogDetail">
        <div className="Content">
          {blog.img && (
            <img src={blog.img} alt={blog.title} className="HeaderImage" />
          )}
          <br />
          <h2>{blog.title}</h2>
          <div className="ContentBox">
          {blog.contentBlocks.map((block, index) => {
           if (block.type === "content") {
            const parts = block.content.split(/(<iframe.*?<\/iframe>)/g); // İçeriği iframe'lere göre böl
            
            return (
              <div key={index}>
                {parts.map((part, idx) => {
                  if (part.startsWith("<iframe")) {
                    // Eğer parça iframe ise
                    return (
                      <div
                        key={idx}
                        className="codeSandBox"
                        dangerouslySetInnerHTML={{ __html: part }}
                        style={{
                          margin: "20px 0",
                          border: "2px solid #333",
                          borderRadius: "10px",
                          overflow: "hidden",
                        }}
                      />
                    );
                  } else {
                    // Eğer parça metin ise
                    return (
                      <div
                        key={idx}
                        style={{
                          marginBottom: "15px",
                          marginTop: "20px",
                          marginLeft: "5px",
                          fontSize: "24px",
                          fontWeight: "300",
                          color: "#fff",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: highlightText(formatContent(part)),
                        }}
                      />
                    );
                  }
                })}
              </div>
            );
          }
          
            
            return null;
          })}
          </div>
          <Link to="/" className="BackButton">
            <FaAngleDoubleLeft size="50" />
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetail;
