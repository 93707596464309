import { useState, useEffect } from "react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../Lib/firebase";
import { Link } from "react-router-dom";
import "./AllBlogs.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Tüm blogları getirme fonksiyonu
  const fetchAllBlogs = async () => {
    try {
      const blogCollection = collection(db, "blogs");
      const q = query(
        blogCollection,
        orderBy("createdAt", "desc") // En yeni bloglar üstte
      );

      const blogSnapshot = await getDocs(q);
      const blogList = blogSnapshot.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().Header,
        img: doc.data().Image,
        shortdesc: doc.data().shortdesc,
        author: doc.data().author,
        createdAt: new Date(doc.data().createdAt).toLocaleDateString("tr-TR"),
      }));

      setBlogs(blogList);
    } catch (error) {
      console.error("Blog getirme hatası:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading">Yükleniyor...</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="all-blogs-container">
        <h1>Tüm Blog Yazıları</h1>
        <div className="blogs-grid">
          {blogs.map((blog) => (
            <div className="Box" key={blog.id}>
              <img src={blog.img} alt={blog.title} loading="lazy" />
              <div className="details">
                <h1>{blog.title}</h1>
                <p>{blog.shortdesc}</p>
                <Link to={`/blog/${blog.id}`} className="ReadMoreBtn">
                  Devamını Oku
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllBlogs;
