// src/pages/HomePage.js
import React, { lazy, Suspense } from "react";

// Lazy loading ile component'lerin yüklenmesi
const Navbar = lazy(() => import("./Web/Navbar"));
const Head = lazy(() => import("./Web/Head"));
const Slider = lazy(() => import("./Web/Slider"));
const Projects = lazy(() => import("./Web/Projects"));
const Blog = lazy(() => import("./Web/Blog"));
const Experience = lazy(() => import("./Web/Experience"));
const Footer = lazy(() => import("./Web/Footer"));

const HomePage = () => {
  return (
    <div className="HomePage">
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar />
        <Head />
        <Slider />
        <Projects />
        <Blog />
        <Experience />
        <Footer />
      </Suspense>
    </div>
  );
};

export default React.memo(HomePage);
