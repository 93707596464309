import "./App.css";
import React from "react";
import BlogDetail from "./Blog/BlogDetail.js";
import HomePage from "./HomePage.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SocialMedia from './SocialMedia/Main.js';
import Portfolio from "./Portfolio/Main.js";
import Login from './Web/Login';
import CreateBlog from './Web/CreateBlog';
import Logout from "./Web/Logout";
import PrivateRoute from "./components/PrivateRoute";
import AllBlogs from "./Web/AllBlogs";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-Main">
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/blog/:id" element={<BlogDetail />} />
              <Route path="SocialMedia" element={<SocialMedia />} />
              <Route path="Portfolio" element={<Portfolio />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route
                path="/create-blog"
                element={
                  <PrivateRoute>
                    <CreateBlog />
                  </PrivateRoute>
                }
              />
              <Route path="/all-blogs" element={<AllBlogs />} />
            </Routes>
          </Router>
        </div>
      </header>
    </div>
  );
}

export default App;
