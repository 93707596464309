import { useEffect, useState } from "react";
import "./Main.css";
import Navbar from "../Web/Navbar";
import Memoji from "../materials/memoji2.webp";
import Line from "../materials/BrushSocial.png"
import JoyStick from "../materials/joystick.png";
import Sticker from "../materials/sticker.png";
import Footer from "../Web/Footer";
import { Link } from 'react-router-dom';
const Main = () => {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    const generatePositions = () => {
      const newPositions = [];
      for (let i = 0; i < 15; i++) {
        let validPosition = false;
        let position;

        while (!validPosition) {
          position = {
            top: Math.random() * 100,
            left: Math.random() * 100,
          };

          const collision = newPositions.some(
            (pos) =>
              Math.abs(pos.top - position.top) < 20 &&
              Math.abs(pos.left - position.left) < 20
          );

          if (!collision) {
            validPosition = true;
          }
        }
        newPositions.push(position);
      }
      setPositions(newPositions);
    };

    generatePositions();
  }, []);

  const joystickImages = positions.map((pos, index) => (
    <img
      key={index}
      src={JoyStick}
      alt="Joystick"
      className="Joystick"
      style={{
        top: `${pos.top}%`,
        left: `${pos.left}%`,
        marginLeft: "200px",
        marginTop: "-100px",
        position: "absolute",
        width: "64px",
        opacity: "0.05",
      }}
    />
  ));

  const StickerImages = positions.map((pos, index) => (
    <img
      key={index}
      src={Sticker}
      alt="Joystick"
      className="Joystick"
      style={{
        top: `${pos.top}%`,
        left: `${pos.left}%`,
        marginLeft: "-340px",
        marginTop: "0px",
        position: "absolute",
        width: "256px",
        opacity: "0.05",
      }}
    />
  ));
  return (
    <div>
      <Navbar />
      <div className="SocialMedia">
      {joystickImages}
      {StickerImages}
        <img src={Line} alt="Memoji" className="Line" />
        <h1>SocialMedia</h1>
        <img src={Memoji} alt="Memoji" className="Memoji" />
        <ul class="social-links">
          <li>
            <a href="https://www.youtube.com/@BayYazilimcicom">YouTube</a>
          </li>
          <li>
            <a href="#">Instagram</a>
          </li>
          <li>
            <a href="#">Tiktok</a>
          </li>
          <li>
            <Link to='/'>Web</Link>
          </li>
           <li>
           <Link to='/'>Go Back</Link>
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
