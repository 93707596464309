import './Navbar.css';
import Logo from '../materials/Logo.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className="navbar">
        <img src={Logo} className="navbar-logo" alt="logo" />
        <ul>
            <li><Link to='/'>Home</Link></li>
            <li><Link to='/SocialMedia'>Social</Link></li>
            <li><Link to='/Portfolio'>Portfolio</Link></li>
            <li><Link to='/contact' className='active'>Contact Me</Link></li>
        </ul>
    </div>
  );
}

export default Navbar;
