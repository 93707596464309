import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../Lib/firebase";
import { signOut } from "firebase/auth";
import "./Logout.css";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = async () => {
      try {
        await signOut(auth);
        // Tüm local storage ve session'ları temizle
        localStorage.clear();
        sessionStorage.clear();
        
        // Sayfayı yenile ve ana sayfaya yönlendir
        window.location.href = '/';
      } catch (error) {
        console.error("Çıkış yaparken hata:", error);
        window.location.href = '/';
      }
    };

    logoutUser();
  }, []);

  return (
    <div className="logout-container">
      <div className="logout-box">
        <h2>Çıkış Yapılıyor...</h2>
      </div>
    </div>
  );
};

export default Logout; 