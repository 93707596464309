import React, { useState, useEffect } from "react";
import { collection, addDoc } from "firebase/firestore";
import { auth, db } from "../Lib/firebase";
import { useNavigate } from "react-router-dom";
import "./CreateBlog.css";

const CreateBlog = () => {
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [contentBlocks, setContentBlocks] = useState([
    { type: "content", content: "" }
  ]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!user) {
        alert("Blog eklemek için giriş yapmalısınız!");
        navigate("/login");
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "white"
        }}
      >
        Yükleniyor...
      </div>
    );
  }

  const addNewBlock = (type) => {
    setContentBlocks([...contentBlocks, { type, content: "" }]);
  };

  const updateBlockContent = (index, content) => {
    const newBlocks = [...contentBlocks];
    newBlocks[index].content = content;
    setContentBlocks(newBlocks);
  };

  const changeBlockType = (index, newType) => {
    const newBlocks = [...contentBlocks];
    newBlocks[index].type = newType;
    setContentBlocks(newBlocks);
  };

  const deleteBlock = (index) => {
    const newBlocks = contentBlocks.filter((_, i) => i !== index);
    setContentBlocks(newBlocks);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!auth.currentUser) {
      alert("Blog eklemek için giriş yapmalısınız!");
      navigate("/login");
      return;
    }

    setLoading(true);

    try {
      const blogsCollectionRef = collection(db, "blogs");

      const formattedContentBlocks = contentBlocks.map(block => ({
        ...block,
        content: block.content.replace(/\n/g, '\\n')
      }));

      const blogData = {
        Header: title,
        Image: imageUrl,
        shortdesc: shortDesc,
        contentBlocks: formattedContentBlocks,
        createdAt: new Date().toISOString(),
        author: auth.currentUser.email
      };

      console.log("Firebase'e gönderilen blog verisi: ", blogData);

      await addDoc(blogsCollectionRef, blogData);
      alert("Blog başarıyla eklendi!");
      navigate("/");
    } catch (error) {
      console.error("Blog eklenirken hata:", error);
      alert(`Blog eklenirken bir hata oluştu: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Bu fonksiyon, \n'leri <br /> etiketi ile değiştirecek
  const formatContent = (content) => {
    return content.split('\\n').map((item, index) => (
      <span key={index}>
        {item}
        <br />
      </span>
    ));
  };

  if (!auth.currentUser) {
    return <div>Yönlendiriliyor...</div>;
  }

  return (
    <div className="create-blog">
      <h1>Yeni Blog Yazısı</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Başlık</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            placeholder="Blog başlığını girin"
          />
        </div>

        <div className="form-group">
          <label>Resim URL</label>
          <input
            type="url"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
            required
            placeholder="Resim URL'sini girin"
          />
        </div>

        <div className="form-group">
          <label>Kısa Açıklama</label>
          <textarea
            value={shortDesc}
            onChange={(e) => setShortDesc(e.target.value)}
            required
            placeholder="Blog için kısa açıklama girin"
            rows="3"
          />
        </div>

        {contentBlocks.map((block, index) => (
          <div key={index} className="content-block">
            <div className="block-header">
              <select
                value={block.type}
                onChange={(e) => changeBlockType(index, e.target.value)}
              >
                <option value="content">Metin</option>
                <option value="code">Kod</option>
              </select>
              <button
                type="button"
                onClick={() => deleteBlock(index)}
                className="delete-block"
              >
                Sil
              </button>
            </div>
            <textarea
              value={block.content}
              onChange={(e) => updateBlockContent(index, e.target.value)}
              placeholder={block.type === "code" ? "Kod bloğunu buraya yazın" : "Metin içeriğini buraya yazın"}
              rows="5"
              className={block.type === "code" ? "code-input" : ""}
            />
          </div>
        ))}

        <div className="block-actions">
          <button type="button" onClick={() => addNewBlock("content")}>
            + Metin Ekle
          </button>
          <button type="button" onClick={() => addNewBlock("code")}>
            + Kod Ekle
          </button>
        </div>

        <button type="submit" disabled={loading} className="submit-btn">
          {loading ? "Ekleniyor..." : "Blog Ekle"}
        </button>
      </form>

      {/* İçeriği formatlayarak render etme */}
      <div className="formatted-content">
        {contentBlocks.map((block, index) => (
          <div key={index} className={`content-block-${block.type}`}>
            {block.type === "content" && formatContent(block.content)}
            {block.type === "code" && (
              <pre className="code-block">
                <code>{block.content}</code>
              </pre>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreateBlog;