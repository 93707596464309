import "./Footer.css";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/login');
  };

  return (
    <div className="Footer">
      <h1>BayYazilimci</h1>
      <ul className="Menu">
        <h6>Header</h6>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/SocialMedia">Social</Link>
        </li>
        <li>
          <Link to="/Portfolio">Portfolio</Link>
        </li>
        <li>
          <Link to="/">Contact Me</Link>
        </li>
      </ul>
      <ul className="Links">
        <h6>Social Media</h6>
        <li>
          <a href="https://github.com/BayYazilimci">Github</a>
        </li>
        <li>
          <a href="#">Instagram</a>
        </li>
        <li>
          <a href="#">Tiktok</a>
        </li>
        <li>
          <a href="https://www.youtube.com/@BayYazilimcicom">YouTube</a>
        </li>
      </ul>
      <ul className="News">
        <h6>News</h6>
        <li>
          <a href="#">
            Blog <span>(Active)</span>
          </a>
        </li>
        <li>
          <a href="#">
            Forum <span>(Soon)</span>
          </a>
        </li>
        <li>
          <a onClick={handleNavigation}>Login</a> {/* Fixed here with onClick */}
        </li>
      </ul>
    </div>
  );
};

export default Footer;
