import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Main.css";
import Navbar from "../Web/Navbar";
import Footer from "../Web/Footer";
import Lua1 from "../materials/printer.png";
import Lua2 from "../materials/EscMenu.png";

const Main = () => {
  const location = useLocation();
  const [visibleBox, setVisibleBox] = useState(1);

  useEffect(() => {
    // Projects.js'den gelen visibleBox değerini kontrol et
    if (location.state?.visibleBox) {
      setVisibleBox(location.state.visibleBox);
    }
    // Sayfanın en üstüne scroll yap
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <Navbar />
      <div className="BlogDetail">
        <h1>Portfolio</h1>

        <button onClick={() => setVisibleBox(1)}className="Gamebtn">Game</button>
        <button onClick={() => setVisibleBox(2)} className="WebBtn">Web</button>

        <div className="boxes">
          {visibleBox === 1 && (
            <div className="Lua">
              <div className="Box1">
                <h1>GAME 1</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </p>
                <img alt="Portfolio" src={Lua1} />
                <span>Lua</span>
              </div>
            </div>
          )}
          {visibleBox === 2 && (
            <div className="box box2">
              <div className="Lua">
                <div className="Box1">
                  <h1>WEB 1</h1>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum
                  </p>
                  <img alt="Portfolio" src={Lua1} />
                  <span>Lua</span>
                </div>
                <div className="Box2">
                  <h1>Box 1</h1>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum
                  </p>
                  <img alt="Portfolio" src={Lua1} />
                  <span>Lua</span>
                </div>
                <div className="Box3">
                  <h1>Box 1</h1>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum
                  </p>
                  <img alt="Portfolio" src={Lua1} />
                  <span>Lua</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
